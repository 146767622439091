import { isEmpty } from 'lodash';
import React from 'react';

const styles = {
  questionLabelContainer: {
    marginTop: 15,
    paddingLeft: 16,
    paddingBottom: 8,
  },
  questionLabel: {
    fontSize: 12,
    color: '#728D9A',
  },
};

const QuestionLabel = (props) => {
  const {label, mandatory} = props;

  return (
    <div style={styles.questionLabelContainer}>
      {!isEmpty(label) &&
      <div style={styles.questionLabel}>{label} {mandatory ? <span>*</span> : ""}</div>}
    </div>
  );
};

export default QuestionLabel;
