import withStyles from "@material-ui/core/styles/withStyles";
import React, {useState, useEffect, useRef} from "react";
import * as propTypes from "prop-types";
import Message from "components/Chat/Message";
import { isEmpty, orderBy} from 'lodash';
import {getMemberName, getMemberDisplayPicture, getChannelMemberData} from "common/helpers";
import {getChannelFor} from "business/channel";
import InfiniteScroll from "react-infinite-scroll-component";
import {useTranslation} from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

const styles = theme => ({
  customScrollbar: {
    display: 'flex',
    flexDirection: 'column-reverse',
    background: '#F0EFEB',
    overflow: 'hidden',
    padding: 16,
  }
});

const ChatMessageWrapper = ({
  classes,
  messages,
  myId,
  channelMembers,
  loadMoreMessages,
  hasMore,
  isFullScreen,
  isBlackOutPeriodActive,
  previewImage,
  channel,
  chatChannels,
  selectedChannelId,
  selectedMessageId,
  setIsMessageSelected,
  isMessageSelected,
  botUsers,
  handleOpenCalender,
  isAIChannel,
})=> {
  const {t} = useTranslation();
  const theme = useTheme();
  const messageRefs = useRef({});
  const [scrollToSelectedMessageEnable, setScrollToSelectedMessageEnable] = useState(!isEmpty(selectedMessageId));
  const isTabletView = useMediaQuery(theme.breakpoints.down('md'));
  const [reverseOrderedMessage, setReverseOrderedMessage] = useState(orderBy(messages, ['created_on', 'msg_id'], ['desc', 'desc']))
  const [membersLastViewedMessageIndexes, setMembersLastViewedMessageIndexes] = useState([]);
  const [loadingSelectedMessageScrolling, setLoadingSelectedMessageScrolling] = useState(false)

  useEffect(() => {
    setReverseOrderedMessage(orderBy(messages, ['created_on', 'msg_id'], ['desc', 'desc']))
  }, [messages, channel])

  useEffect(() => {
    const channelMembersList = getChannelFor(selectedChannelId, chatChannels).channelMembers
    const membersLastViewedMessageIndexes = channelMembersList
      .filter((member) => member.userId !== myId)
      .map((member) => {
        const memberLastViewedTimestamp = member.lastViewedTimestamp ? new Date(member.lastViewedTimestamp) : null;
        const messageIndex = reverseOrderedMessage.findIndex(
          (message) =>
          memberLastViewedTimestamp && new Date(message.created_on) <= memberLastViewedTimestamp
        );
        return { messageIndex, memberId: member.userId };
      });
      setMembersLastViewedMessageIndexes(membersLastViewedMessageIndexes);
  }, [reverseOrderedMessage, chatChannels]);

  useEffect(() => {
    if (selectedMessageId === null && reverseOrderedMessage.length > 0) {
      const lastMessageId = reverseOrderedMessage[0].msg_id;
      messageRefs.current[lastMessageId].scrollIntoView();
    } else if (selectedMessageId && messageRefs.current[selectedMessageId]) {
      if (scrollToSelectedMessageEnable || isMessageSelected) {
        messageRefs.current[selectedMessageId].scrollIntoView({block: 'end'});
        setScrollToSelectedMessageEnable(false)
        setIsMessageSelected(false)
      }
    }
    setLoadingSelectedMessageScrolling(false)
  }, [reverseOrderedMessage, selectedMessageId, isMessageSelected]);

  useEffect(() => {
    selectedMessageId && setScrollToSelectedMessageEnable(true)
    selectedMessageId && setLoadingSelectedMessageScrolling(true)
  }, [selectedMessageId]);

  return (
    <>
      {loadingSelectedMessageScrolling ?
        <div style={{ background: '#F0EFEB', height: isFullScreen ? isTabletView ? '80vh' : 447 : 300 }}>
          <p style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.4)"}}>
            {t("Loading...")}
          </p>
        </div>
        :
        <InfiniteScroll
          height={isFullScreen ? isTabletView ? '80vh' : 447 : 300}
          className={classes.customScrollbar}
          dataLength={reverseOrderedMessage.length}
          scrollableTarget="scrollableDiv"
          next={loadMoreMessages}
          hasMore={hasMore}
          inverse={true}
          endMessage={
            <p style={{textAlign: 'center', color: 'rgba(0, 0, 0, 0.4)'}}>
              {isAIChannel ? t("This is the beginning of AI Assistant chat!") : t("This is the beginning of chat!")}
            </p>
          }
          loader={
            <p style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.4)" }}>
              {t("Loading...")}
            </p>
          }
        >
          {reverseOrderedMessage.length > 0 && reverseOrderedMessage.map((message, index) => {
            const matchingIndexDataArray = membersLastViewedMessageIndexes.filter((data) => data.messageIndex === index);
            const matchingMemberIds = matchingIndexDataArray ? matchingIndexDataArray.map((data) => data.memberId) : [];
            return (
              <div
                key={message.msg_id}
                ref={(ref) => (messageRefs.current[message.msg_id] = ref)}
              >
                <Message
                  isSelected={selectedMessageId === message.msg_id}
                  userName={getMemberName(message.user_id ? message.user_id.toString() : '', channelMembers, botUsers)}
                  messageContent={message.message}
                  messageStatus={message.status}
                  messageAttachments={JSON.parse(message.attachments || null)}
                  messageDate={message.created_on}
                  isBlackOutPeriodActive={isBlackOutPeriodActive}
                  isSelf={message.user_id ? message.user_id.toString() === myId.toString() : false}
                  displayPicture={getMemberDisplayPicture(message.user_id ? message.user_id.toString() : '', channelMembers, botUsers)}
                  onClickImage={(uri) => previewImage(uri)}
                  membersData={matchingMemberIds.map((matchingMemberId) =>
                    getChannelMemberData(matchingMemberId.toString(), channelMembers, botUsers)
                  )}
                  myId={myId}
                  handleOpenCalender={handleOpenCalender}
                  isAIChannel={isAIChannel}
                />
              </div>
            );
          })}
        </InfiniteScroll>
      }
    </>
  );
};

export default withStyles(styles)(ChatMessageWrapper);


ChatMessageWrapper.propTypes = {
  messages: propTypes.object,
  isFullScreen: propTypes.bool
};

ChatMessageWrapper.defaultProps = {
  isFullScreen: false
}
