import withStyles from "@material-ui/core/styles/withStyles";
import React, {useRef, useState} from "react";
import * as propTypes from "prop-types";
import MoodIcon from '@material-ui/icons/Mood';
import Close from '@material-ui/icons/Close';
import GifOutlinedIcon from '@material-ui/icons/GifOutlined';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Send from '@material-ui/icons/Send';
import {isEmpty} from 'lodash';
import {Picker} from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'
import ReactGiphySearchbox from 'react-giphy-searchbox'
import './chat.css'
import Accordion from "@material-ui/core/Accordion/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails/AccordionDetails";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {useTranslation} from "react-i18next";
import { truncateFileName, getFileExtensionFromName } from "common/helpers";
import { MentionsInput, Mention } from 'react-mentions'
import config from "common/config";
import Avatar from "@material-ui/core/Avatar/Avatar";

const styles = theme => ({
  attachmentWrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    backgroundColor:'white',
    color:'black',
    fontWeight: 'bold',
    fontSize: 12,
    padding:4,
    borderRadius: 6,
    margin: 0,
    marginTop: 2,
    marginBottom: 6,
    shadowColor: 'transparent',
    width: 'min-content',
    whiteSpace: 'nowrap'
  },
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flex: 1,
    padding: '4px 6px',
    borderTop: '0.5px solid rgba(0, 0, 0, 0.15)',
    background: '#F0EFEB',
  },
  sendButton: {
    fontSize: 21,
    color: '#E0B334',
    cursor: 'pointer'
  },
  whiteTextBoxContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    borderRadius: 8,
    padding: 8,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    color: '#000',
  },
  transparentTextBoxContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    borderRadius: 8,
    padding: 8,
    backgroundColor: 'transparent'
  },
  textFieldWrapper: {
    width: '100%',
    marginRight: 10
  },
  emojiContainer: {
    padding: 0,
    height: 250,
    overflow: 'hidden'
  },
  messageAttachmentName: {
    fontWeight: '500',
    color: 'black',
  },
  messageAttachmentExtension: {
    fontWeight: '700',
    color: '#E0B334',
  },
  clearAttachment: {
    color: '#1C1B1F',
    opacity: 0.4,
    fontSize: 16,
    marginLeft: 5,
    cursor: 'pointer'
  },
  fileUploadAlertMessage: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: '500',
    fontSize: 10,
    opacity: 0.7,
    marginTop: 6,
  },
  alertOutline: {
    marginTop: 1,
    marginRight: 4,
    fontSize: 12,
  },
  mentionListiing: {
    display: 'flex',
    alignItems: 'cemter',
  },
  smallAvatar: {
    width: 16,
    height: 16,
    marginRight: 4,
  },
});


const MessageInputBox = ({classes, placeHolder, onSendClick, myId, channelId, myEmail, channelMembers, addNewMessageToChannel, setSelectedMessageId, isAIChannel, onStartRatingCountDown}) => {
  const {GIPHY_SDK_KEY} = config;
  const [messageText, setMessageText] = useState("");
  const fileInputRef = useRef(null);
  const [isAttachmentAdded, setIsAttachmentAdded] = useState(false);
  const [messageAttachments, setMessageAttachments] = useState([]);
  const [showEmojiTray, setShowEmojiTray] = useState(false);
  const [showGifTray, setShowGifTray] = useState(false);
  const {t} = useTranslation();
  const supportedFileFormat = ['.jpg', '.jpeg', '.gif', '.png', '.webp', '.heic', '.txt', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.csv', '.ppt', '.pptx'];

  const activeChannelMembers = channelMembers.filter((channelMember) => channelMember.isActive)
  const channelMembersExcludingMe = activeChannelMembers.filter((channelMember) => channelMember.userId !== myId);
  const fetchUsers = () => {
    if (channelMembersExcludingMe.length > 0) {
      const tagsArray = channelMembersExcludingMe.map(member => {
        return {
          id: member.userId,
          display: member.userFullName,
          image: member.displayPicture
        }
      })
      return tagsArray
    }
    return []
  }

  const tagListData = fetchUsers()

  const selectAttachment = () => {
    fileInputRef.current.click();
  }

  const clearFileSelection = () => {
    setMessageAttachments([])
    setIsAttachmentAdded(false)
  }

  const handleGifSelection = (item) => {
    setShowGifTray(false)
    const file = {
      url: item.images.downsized_medium.url,
      type: 'gif',
      name: item.title + '.gif'
    }
    setMessageAttachments([file])
    setIsAttachmentAdded(true)
  }

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;

    const validFiles = [];

    if (selectedFiles) {
      const maxSizeInBytes = 5 * 1024 * 1024;
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        if (!supportedFileFormat.includes('.' + getFileExtensionFromName(file.name))) {
          alert('Please select a valid file. \nSupported images are: jpeg, jpg, gif, png, webp, heic \nSupported documents are: txt, pdf, csv, doc, docx, xls, xlsx, ppt, pptx')
          return
        }

        if (file.size <= maxSizeInBytes) {
          validFiles.push(file);
        }
      }
    }

    setMessageAttachments(validFiles);

    if (validFiles.length > 0) {
      setIsAttachmentAdded(true)
    }

    if (validFiles.length != selectedFiles.length) {
      alert('Files with more than 5 MB are not allowed.')
    }
  };

  const onSend = () => {
    if (!isMessageEmpty(messageText) || isAttachmentAdded) {
      let tempId = null;
      if (!isEmpty(messageAttachments)) {
        tempId = (Math.random() + 1).toString(36).substring(2); // generating a random string
        const messageData = prepareTempMessageData(tempId);
        addNewMessageToChannel(messageData);
      }
      let message = {from: myEmail, text: messageText, channelId: channelId, attachments: messageAttachments, tempId: tempId};
      setSelectedMessageId(null)
      onSendClick(message);
      onStartRatingCountDown();
      clearMessage();
      clearFileSelection();
    }
  };

  const prepareTempMessageData = (tempId) => {
    return {
      channel_id: channelId,
      msg_id: tempId,
      temp_id: tempId,
      created_on: Date(),
      user_id: myId,
      message: messageText,
      command: "new_message",
      attachments: JSON.stringify([{
        id: (Math.random() + 1).toString(36).substring(2),
        attachment_name: truncateFileName(messageAttachments[0].name, 13) + ' .' + getFileExtensionFromName(messageAttachments[0].name),
        presigned_url: null,
        thumbnail_presigned_url: null
      }]),
      status: 'in-progress'
    }
  }

  const clearMessage = () => setMessageText("");
  const isMessageEmpty = (message) => isEmpty(message);
  const addEmojiToTextBox = (emoji) => {
    setMessageText(messageText + emoji.native);
  }

  const handleKeyPress = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      onSend();
      event.preventDefault()
    }
  };

  const handleTextInput = (value) => {
      setMessageText(value)
  };

  return (
    <div>
      <div className={classes.wrapper}>
        <div
          style={{ display: 'flex', marginTop: 0 }}
        >
          {!isAIChannel &&
            <IconButton edge={'start'} size={'small'} style= {{ paddingLeft: 6, paddingRight: 6 }} onClick={() => {setShowGifTray(false); setShowEmojiTray(!showEmojiTray)}}>
              <MoodIcon style={{color: '#000000', opacity: 0.5 }}/>
            </IconButton>
          }
          {!isAttachmentAdded && !isAIChannel &&
            <IconButton edge={'start'} size={'small'} style= {{ padding: 0 }} onClick={() => {setShowEmojiTray(false); setShowGifTray(!showGifTray);}}>
              <GifOutlinedIcon style={{ color: '#000000', opacity: 0.5, fontSize: 35 }} />
            </IconButton>
          }
        </div>
        { !isAttachmentAdded && !isAIChannel &&
          <div
            style={{ marginTop: 4 }}
          >
            <input
              type="file"
              ref={fileInputRef}
              hidden
              onClick={(e) => {e.target.value = null}}
              onChange={handleFileChange}
              accept={supportedFileFormat.join(',')}
            />
            <IconButton edge={'start'} size={'small'}>
              <AttachFileIcon onClick={() => selectAttachment()}/>
            </IconButton>
          </div>
        }
        <div
          style={{
            width: '100%',
          }}
        >
          { isAttachmentAdded &&
            <div
              className={classes.attachmentWrapper}
            >
              <span
                className={classes.messageAttachmentName}
              >
                {truncateFileName(messageAttachments[0].name, 13)}
              </span>
              <span
                className={classes.messageAttachmentExtension}
              >
                .{getFileExtensionFromName(messageAttachments[0].name)}
              </span>
              <Close
                className={classes.clearAttachment}
                onClick={() => clearFileSelection()}
              />
            </div>
          }
          <div
            className={!isMessageEmpty(messageText) || isAttachmentAdded ? classes.whiteTextBoxContainer : classes.transparentTextBoxContainer}
          >
          <div className={classes.textFieldWrapper}>
            {!isAIChannel ? (
              <MentionsInput
                style={{
                  suggestions: {
                    list: {
                      backgroundColor: "#FFF",
                      fontSize: 12,
                      borderRadius: 8,
                    },
                    item: {
                      color: 'rgba(0, 0, 0, 0.50)',
                      fontSize: 12,
                      fontWeight: 400,
                      "&focused": {
                        backgroundColor: "rgb(238, 238, 238)",
                      },
                      border: 0,
                      borderRadius: 8,
                      paddingTop: 3,
                      paddingBottom: 3,
                    },
                  }
                }}
                className={'chat-mentions'}
                placeholder={placeHolder || t('Type your Message here')}
                value={messageText}
                onKeyDown={handleKeyPress}
                onChange={(e) => handleTextInput(e.target.value)}
                multiline
                autoFocus
                allowSpaceInQuery={true}
                forceSuggestionsAboveCursor={true}
              >
                <Mention
                  className={'mentions__mention'}
                  trigger="@"
                  data={tagListData}
                  appendSpaceOnAdd
                  displayTransform={(id, display) => '@'+display}
                  renderSuggestion={(suggestion, search, highlightedDisplay  ) => (
                    <div className={classes.mentionListiing}>
                      <Avatar src={suggestion.image} alt={suggestion.display} className={classes.smallAvatar}/>
                      <div className="mention-info">
                        <div>{highlightedDisplay}</div>
                      </div>
                    </div>
                  )}
                />
              </MentionsInput>
            ) : (
              <input
                placeholder={placeHolder || t('Type your Message here')}
                value={messageText}
                onKeyDown={handleKeyPress}
                onChange={(e) => handleTextInput(e.target.value)}
                multiline
                autoFocus
                style={{
                  width: '100%',
                  border: 'none',
                  outline: 'none',
                  padding: '8px',
                  background: 'transparent',
                  fontSize: '14px',
                }}
              />
            )}
          </div>
            { (!isMessageEmpty(messageText) || isAttachmentAdded) &&
            <Send
              className={classes.sendButton}
              onClick={onSend}
            />
            }
          </div>
          { isAttachmentAdded && isMessageEmpty(messageText) &&
            <div className={classes.fileUploadAlertMessage}>
              <ErrorOutline
                className={classes.alertOutline}
              />
              <div>Files sent cannot be deleted. Verify before sending</div>
            </div>
          }
        </div>
      </div>
      <div>
      <Accordion square expanded={showEmojiTray || showGifTray}>
        <AccordionSummary style={{display: 'none'}}>
        </AccordionSummary>
        <AccordionDetails
          classes={{root: classes.emojiContainer}}
        >
          {showGifTray
          &&
          <ReactGiphySearchbox
            apiKey={GIPHY_SDK_KEY} // Required: get your on https://developers.giphy.com
            onSelect={handleGifSelection}
            gifPerPage={10}
            autoFocus
            masonryConfig={[
              { columns: 3, imageWidth: 112, gutter: 10 },
              { mq: '425px', columns: 3, imageWidth: 135, gutter: 10 },
              { mq: '600px', columns: 3, imageWidth: 115, gutter: 10 },
              { mq: '768px', columns: 3, imageWidth: 155, gutter: 10 },
              { mq: '1000px', columns: 4, imageWidth: 150, gutter: 10 },
              { mq: '1200px', columns: 4, imageWidth: 154, gutter: 10 },
            ]}
            poweredByGiphy={false}
            wrapperClassName={'gif-box-wrapper'}
            listWrapperClassName={'gif-list-wrapper'}
            listItemClassName={'gif'}
          />
          }
          {showEmojiTray
          &&
          <Picker
            emoji={''}
            set={'google'}
            showPreview={false}
            showSkinTones={false}
            style={{height: 250, width: '100%'}}
            useButton={false}
            onClick={addEmojiToTextBox}
          />}
        </AccordionDetails>
      </Accordion>
      </div>
    </div>
  );
};

export default withStyles(styles)(MessageInputBox);

MessageInputBox.propTypes = {
  isDisabled: propTypes.bool,
  enableEmoji: propTypes.bool,
  placeHolder: propTypes.string,
  onSendClick: propTypes.func,
  myId: propTypes.number,
  isFullScreen: propTypes.bool
};

MessageInputBox.defaultProps = {
  enableEmoji: false,
  isDisabled: false,
  isFullScreen: false
};
